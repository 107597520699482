import * as React from "react"
import { graphql, PageProps, HeadFC } from "gatsby"
import Layout from "../components/layout"
import { SEO } from "../components/seo"
import { renderOptions, documentToReactComponents, renderRichText } from "../components/renderRichText"
import { Container, Box, Heading, VisuallyHidden } from "../components/ui"

interface QueryReturn {
  page: {
    id: string
    title: string
    slug: string
    description: string
    image: { id: string; url: string }
  }
}

const Page: React.FC<PageProps<QueryReturn>> = ({ data: { page } }) => {
  return (
    <Layout {...page}>
      <Box paddingY={5}>
        <Container width="narrow">
          <VisuallyHidden><Heading as="h1">{page.title}</Heading></VisuallyHidden>
          {
            documentToReactComponents(
              JSON.parse(page.body.raw)
            )
            &&
            renderRichText(
              page.body, renderOptions()
            )
          }
        </Container>
      </Box>
    </Layout>
  )
}

export default Page

export const Head: HeadFC<QueryReturn> = ({ data: { page }, location }) => (
  <SEO title={page.title} description={page.description} pathname={location.pathname} />
)

export const query = graphql`
  query PageContent($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      id
      title
      slug
      description
      image {
        id
        url
      }
      body {
        raw
        references {
          ... on ContentfulAsset {
            contentful_id
            __typename
            publicUrl
            title
            url
          }
          ... on ContentfulBlogPost {
            contentful_id
            __typename
            slug
          }
        }
      }
    }
  }
`